import React from "react";
import { format } from "date-fns";
import pix from "../../assets/chavePixYobom.jpg";
import LogoYobom from "../../assets/icons/LogoYobom.png";

const PrintButtonA4 = ({ orders, caixaData, item }) => {
  const handleImprimirClick = async () => {
    const printWindow = window.open("", "_blank");

    printWindow.document.write(`
      <html>
        <head>
          <title>Informações para Impressão</title>
          <meta name="viewport" content="width=device-width, initial-scale=1.0" />
          <style>
            body {
              font-family: Arial, sans-serif;
              margin: 0;
              padding: 20px;
              width: 210mm;
              height: 297mm;
              display: flex;
              flex-direction: column;
              align-items: center;
            }

            .LogoYobom {
              width: 30%;
              margin-bottom: -10px;
            }

            .endereco {
              font-size: 14px;
              margin-bottom: 10px;
            }

            .dados-compra {
              font-size: 14px;
              margin-top: 20px;
              display: flex;
              justify-content: space-between;
              width: 100%;
            }

            table {
              width: 100%;
              border-collapse: collapse;
              margin-top: 20px;
            }

            th, td {
              border: 1px solid #ddd;
              padding: 8px;
              text-align: left;
            }

            th {
              background-color: #f2f2f2;
            }

            .valor-total {
              font-size: 14px;
              margin-top: 20px;
              font-weight: bold;
              text-align: right;
            }

            .chave-pix {
              font-size: 14px;
              margin-top: 20px;
              display: flex;
              width: 100%;
              justify-content: space-around;
            }

            .qrcode {
              margin-top: -2px;
              max-width: 145px;
              width: 100%;
            }

            .footer {
              margin-top: auto;
              text-align: right;
            }

            .fim {
              display: flex;
              width: 100%;
              margin-top: 30px;
              justify-content: space-around;
            }
          </style>
        </head>
        <body>
          <img class="LogoYobom" src="${LogoYobom}" alt="LogoYobom">
          <p class="endereco">R. Bruxelas, 15 - Planalto, Manaus - AM, 69045-260</p>
          <div class="dados-compra">
            <div class="cliente">
              <p>Cliente: ${item.nome_cliente}</p>
              <p>Telefone: ${item.telefone || "NAO INFORMADO"}</p>
              <p>Email: ${item.email || "NAO INFORMADO"}</p>
              <p>Endereço: ${item.endereco || "NAO INFORMADO"}</p>
            </div>
            <div class="data-compra ">
              <p>Data da Compra: ${format(
                new Date(item.data),
                "dd/MM/yyyy"
              )}</p>
             <p> Valor Pendente: R$${parseFloat(item.diferenca || 0).toFixed(
               2
             )}</p> 
            </div>
          </div>
          <table>
            <thead>
              <tr>
                <th>QTD</th>
                <th>Produto</th>
                <th>Valor Uni (R$)</th>
                <th>Valor Total (R$)</th>
              </tr>
            </thead>
            <tbody>
    `);

    let valorTotal = 0;
    let quantidadeTotal = 0;

    for (const pedido of orders) {
      printWindow.document.write(`
        <tr>
          <td>${pedido.quantidadeSelecionada}x</td>
          <td>${pedido.nome_produto} ${pedido.tamanho}</td>
          <td>R$:${(pedido.ValorTotal / pedido.quantidadeSelecionada).toFixed(
            2
          )}</td>
          <td>R$:${pedido.ValorTotal}</td>
        </tr>
      `);

      valorTotal += parseFloat(pedido.ValorTotal);
      quantidadeTotal += parseInt(pedido.quantidadeSelecionada);
    }

    printWindow.document.write(`
            <tr>
              <td colspan="2" class="valor-total">Total de Produtos: ${quantidadeTotal}</td>
              <td class="valor-total">Valor Total dos Produtos:</td>
              <td class="valor-total">R$:${valorTotal.toFixed(2)}</td>
            </tr>
          </tbody>
        </table>
    `);

    printWindow.document.write(`
          <div class="chave-pix">
            <div> 
              <h3>MANDE O COMPROVANTE PARA ESSE NUMERO: (92)9 9114-3062</h3>
              <div> 
                <h4>Forma de pagamento:</h4>
                <p>[ PIX ]:</p>
                <p>[ DINHEIRO ]:</p>
                <p>[ CARTÃO ]:</p>
              </div>
            </div>
          
            <div> 
                <p>Chave Pix: (92) 993216300</p>
                <p>QRCode do Pix:</p>
                <img class="qrcode" src="${pix}" alt="QRCode do Pix" onload="window.print()">
              </div>
          </div>
        </body>
      </html>
    `);
    printWindow.document.write(`
    <div class="fim">
      <div> 
        <p>CLiente:</p>
        <p>X______________________________</p>
      </div>
      <div> 
        <p>Data:</p>
        <p>_____/_____/_____</p>
      </div>
      
      <div> 
        <p>Entregador:</p>
        <p>X______________________________</p>
      </div>
    </div>
    `);

    printWindow.document.close();
  };

  return (
    <button type="button" onClick={handleImprimirClick}>
      Imprimir A4
    </button>
  );
};

export default PrintButtonA4;
